const getters = {
  getAppLoading(state) {
    return state.isAppLoading;
  },
  getLang(state) {
    return state.lang;
  },
  getLocale(state) {
    return state.locale;
  },
};

const actions = {
  setAppLoading({ commit }, value) {
    commit('SET_APP_LOADING', value);
  },
};

const mutations = {
  SET_APP_LOADING(state, value) {
    state.isAppLoading = value;
  },
};
const state = {
  isAppLoading: false,
  lang: import.meta.env.VUE_APP_LANG,
  locale: import.meta.env.VUE_APP_DATE_LANG,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
