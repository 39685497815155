const getters = {
  getTeachers: state => state.list,
  isLoaded: state => state.isLoaded,
};

const actions = {
  async setTeachers({ commit }, data) {
    // console.log('setTeachers');
    // console.log(data);
    commit('SET_TEACHERS', data);
  },
};

const mutations = {
  SET_TEACHERS(state, teachers) {
    console.log('SET_TEACHERS', teachers);
    // console.log(teachers);
    // if (teachers) {
    if (teachers?.data) {
      state.list = [...teachers?.data];
      state.isLoaded = true;
    } else {
      state.list = [];
      state.isLoaded = true;
    }
    // }
  },
};

const state = {
  isLoaded: false,
  list: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
