import api from '@api';

const getters = {
  getIsAuthenticated(state) {
    return state.isAuthenticated;
  },
  getUser(state) {
    console.log('getUser');
    // console.log(state);
    // console.log(state.user);
    return state.user;
  },
  getPermissions(state) {
    console.log('getPermissions');
    return state.permissions;
  },
  getToken(state) {
    return state.userToken;
  },
};

const actions = {
  auth({ commit }) {
    commit('AUTH_REQUEST');
    return new Promise((resolve, reject) => {
      api.auth.auth().then(({ data }) => {
        console.log('actions auth AUTH_REQUEST');
        resolve(data);
        commit('AUTH_SUCCESS', data);
      }).catch((e) => {
        commit('AUTH_FAILURE');
        reject(e);
      });
    });
  },
  authEclass({ commit }, { code }) {
    commit('LOGIN_REQUEST');
    return new Promise((resolve, reject) => {
      api.auth.authEclass(code).then(({ data }) => {
        resolve(data);
        commit('LOGIN_SUCCESS', data.access_token);
      }).catch((e) => {
        console.log(e);
        commit('LOGIN_FAILURE');
        reject(e);
      });
    });
  },
  permissions({ commit }) {
    commit('PERMISSIONS_REQUEST');
    return new Promise((resolve, reject) => {
      api.auth.permissions().then((response) => {
        resolve(response);
        commit('PERMISSIONS_SUCCESS', response);
      }).catch((e) => {
        console.log(e);
        commit('PERMISSIONS_FAILURE');
        reject(e);
      });
    });
  },
  login({ commit }, { email, password }) {
    console.log('store auth login');
    commit('LOGIN_REQUEST');
    console.log('store auth login commit');
    return new Promise((resolve, reject) => {
      console.log('store auth login commit Promise');
      console.log(api);
      console.log(api.auth);
      api.auth.login({ email, password }).then((response) => {
        console.log('store auth login commit Promiseapi.auth.login');
        resolve(response);
        commit('LOGIN_SUCCESS', response.access_token);
      }).catch((e) => {
        console.log('store auth login commit Promise catch');
        console.log(e);
        commit('LOGIN_FAILURE');
        reject(e);
      });
    });
  },
  logout({ commit }) {
    commit('LOGOUT_REQUEST');
    return new Promise((resolve, reject) => {
      api.auth.logout().then(({ data }) => {
        localStorage.removeItem('user-token');
        resolve(data);
        commit('LOGOUT_SUCCESS');
      }).catch((e) => {
        console.log(e);
        commit('LOGOUT_FAILURE');
        reject(e);
      });
    });
  },
  register({ commit }, payload) {
    commit('REGISTER_REQUEST');
    return new Promise((resolve, reject) => {
      api.auth.register(payload).then((response) => {
        resolve(response);
        commit('REGISTER_SUCCESS', response.access_token);
      }).catch((e) => {
        console.log(e);
        commit('REGISTER_FAILURE');
        reject(e);
      });
    });
  },
};

const mutations = {
  AUTH_REQUEST() { },
  AUTH_SUCCESS(state, payload) {
    localStorage.setItem('isAuthenticated', true);
    console.log('mutations AUTH_SUCCESS');
    console.log(payload);
    state.user = { ...payload };
  },
  AUTH_FAILURE(state) {
    state.isAuthenticated = false;
    localStorage.removeItem('isAuthenticated');
  },
  LOGIN_REQUEST() { },
  LOGIN_SUCCESS(state, token) {
    state.userToken = token;
    localStorage.setItem('user-token', token);
  },
  LOGIN_FAILURE(state) {
    state.isAuthenticated = false;
  },
  LOGOUT_REQUEST() { },
  LOGOUT_SUCCESS() {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('user-token');
  },
  LOGOUT_FAILURE() {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('user-token');
  },
  REGISTER_REQUEST() { },
  REGISTER_SUCCESS(state, token) {
    state.userToken = token;
    localStorage.setItem('user-token', token);
  },
  REGISTER_FAILURE() {
    localStorage.removeItem('isAuthenticated');
  },
  PERMISSIONS_REQUEST() { },
  PERMISSIONS_SUCCESS(state, data) {
    state.permissions = data;
  },
  PERMISSIONS_FAILURE() {
    // localStorage.removeItem('isAuthenticated');
  },
};

const state = {
  userToken: localStorage.getItem('user-token') || null,
  isAuthenticated: localStorage.getItem('isAuthenticated') || null,
  user: {},
  permissions: {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
