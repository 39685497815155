// import camelCase from 'lodash/camelCase';

import auth from '@api/modules/auth';
import categories from '@api/modules/categories';
import events from '@api/modules/events';
import groups from '@api/modules/groups';
import individualPlan from '@api/modules/individualPlan';
import password from '@api/modules/password';
import roles from '@api/modules/roles';
import rooms from '@api/modules/rooms';
import roomSchedule from '@api/modules/roomSchedule';
import statuses from '@api/modules/statuses';
import students from '@api/modules/students';
import subjects from '@api/modules/subjects';
import teachers from '@api/modules/teachers';
import users from '@api/modules/users';

const includeModules = {};

includeModules['auth'] =  auth;
includeModules['categories'] =  categories;
includeModules['events'] =  events;
includeModules['groups'] =  groups;
includeModules['individualPlan'] =  individualPlan;
includeModules['password'] =  password;
includeModules['roles'] =  roles;
includeModules['rooms'] =  rooms;
includeModules['roomSchedule'] =  roomSchedule;
includeModules['statuses'] =  statuses;
includeModules['students'] =  students;
includeModules['subjects'] =  subjects;
includeModules['teachers'] =  teachers;
includeModules['users'] =  users;
export default includeModules;
