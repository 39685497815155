import constants from '@constants';

export default {
  data: () => ({
  }),
  computed: {
    // isLoading: {
    //   get: function () {
    //     return this.$store.getters['loading/isLoading']
    //   }
    // },
    getIsAuthenticated: {
      get() {
        return this.$store.getters['auth/getIsAuthenticated'];
      },
    },
    getLang: {
      get() {
        return this.$store.getters['global/getLang'];
      },
    },
    getLocale: {
      get() {
        return this.$store.getters['global/getLocale'];
      },
    },
    _getUser: {
      get() {
        return this.$store.getters['auth/getUser'];
      },
    },
    isAppLoading: {
      get() {
        return this.$store.getters['global/getAppLoading'];
      },
    },
  },
  methods: {
    _showErrorNotify(text) {
      this.$notify({
        group: 'global',
        type: 'error',
        text,
      });
    },
    _showSuccessNotify(text) {
      this.$notify({
        group: 'global',
        type: 'success',
        text,
      });
    },
    _$can(action, permission) {
      const permissions = this.$store.getters['auth/getPermissions'];
      return permissions[permission] ? permissions[permission].indexOf(action) > -1 : false;
    },
    _setEvent(data) {
      this.$store.dispatch('eventForm/setData', {
        ...data,
        category_id: data.category.id || null,
        status_id: data.status.id || null,
        room_id: data.room.id || null,
        subject_id: data.subject ? data.subject.id : null,
        students: data.students || [],
        teachers: data.teachers || [],
        start_date: this.$moment(
          data.start_datetime, constants.dateTimeFormat,
        ).format(constants.backendDateFormat),
        start_time: this.$moment(
          data.start_datetime, constants.dateTimeFormat,
        ).format(constants.timeFormat),
        end_time: this.$moment(
          data.end_datetime, constants.dateTimeFormat,
        ).format(constants.timeFormat),
      });
    },
    isUndefined(value) {
      return value === undefined;
    },
    isNull(value) {
      return value === null;
    },
    isBoolean(value) {
      return typeof value === 'boolean';
    },
    isString(value) {
      return typeof value === 'string';
    },
    isObject(value) {
      return value === Object(value);
    },
    isArray(value) {
      return Array.isArray(value);
    },
    isNumber(value) {
      return value !== null && typeof value.size === 'number';
    },
    isFunction(value) {
      return value && {}.toString.call(value) === '[object Function]';
    },
    copyData(data) {
      if (this.isUndefined(data) || this.isNull(data)) {
        return null;
      } if (this.isArray(data)) {
        return Object.keys(data).map(i => ({ ...data[i] }));
      } if (this.isObject(data)) {
        return { ...data };
      }

      throw new Error('Argument must be a object ot array');
    },
    copyObject(src) {
      const target = {};
      // eslint-disable-next-line
      for (const prop in src) {
        // eslint-disable-next-line
        if (src.hasOwnProperty(prop)) {
          target[prop] = src[prop];
        }
      }
      return target;
    },
    findIndexById(array, id) {
      return array.findIndex(item => item.id === id);
    },
    booleanToNumber(value) {
      if (value) {
        return 1;
      }
      return 0;
    },
    numberToBoolean(num) {
      if (num > 0) {
        return true;
      }
      return false;
    },
    _confirmAction(text) {
      // eslint-disable-next-line
      return window.confirm(text);
    },
    _convertArrayToString(arr, value) {
      return arr.reduce((result, item, index) => {
        if (index) {
          // eslint-disable-next-line no-param-reassign,no-return-assign
          return result += `, ${item[value]}`;
        }

        // eslint-disable-next-line no-param-reassign,no-return-assign
        return result += `${item[value]}`;
      }, '');
    },
  },
  filters: {
    convertNumberToDecimal(number) {
      return (number < 10) ? `0${number.toString()}` : number.toString();
    },
  },
};
