const getters = {
  getStudents: state => state.list,
  getSelectedStudents: state => state.selectedStudents,
  isLoaded: state => state.isLoaded,
};

const actions = {
  async setStudents({ commit }, data) {
    // console.log('setTeachers');
    // console.log(data);
    commit('SET_STUDENTS', data);
  },
  setSelectedStudents({ commit }, data) {
    commit('SET_SELECTED_STUDENTS', data);
  },
};

const mutations = {
  SET_STUDENTS(state, students) {
    // console.log('SET_STUDENTS');

    //   console.log(students);
    //   state.list = [...student?.map((group) => {
    //     // eslint-disable-next-line
    //     group.platform_students = [
    //       ...group?.platform_students?.map((student) => {
    //         // eslint-disable-next-line
    //         student.key = `student_${student.id}`;
    //         return student;
    //       }),
    //     ];
    //     // eslint-disable-next-line
    //     group.key = `group_${group.id}`;
    //     return group;
    //   })];
    //   state.isLoaded = true;
    // },
    if (Array.isArray(students.data)) {
      state.list = students.data.map((group) => {
        group.platform_students = group?.platform_students?.map((student) => {
          student.key = `student_${student.id}`;
          return student;
        });
        group.key = `group_${group.id}`;
        return group;
      });
    } else {
      state.list = [];
    }
    state.isLoaded = true;
  },
  SET_SELECTED_STUDENTS(state, data) {
    state.selectedStudents = [...data];
  },
};

const state = {
  isLoaded: false,
  selectedStudents: [],
  list: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
