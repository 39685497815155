import http from '@utils/http';
import store from '@store';

export default {
  fetchData() {
    return new Promise((resolve, reject) => {
      http.get('/api/teachers').then(
        ({ data }) => {
            // console.log(data);
            // console.log('store');
            // console.log(store);
          // store.actions.teachers.setTeachers(data);
            // store.dispatch(teachersModule.actions.setTeachers, data)
          // store.state.teachers('teachers/setTeachers', data);
          //   this.$store.dispatch
          store.dispatch('teachers/setTeachers', data);
            // resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
};
